<template>
  <div class="root_layout">
    <van-loading type="spinner" size="30px" text-size="18px">{{
      showText
    }}</van-loading>
  </div>
</template>

<script>
import * as util from '../../api/util'
import * as common from '../../api/common'
import axios from 'axios'

export default {
  name: 'thr_coupon',
  data () {
    return {
      showText: '加载中...'
    }
  },
  mounted () {
    if (!this.$route.query.sid || !this.$route.query.source) {
      this.skipError('地址加载异常，缺少数据！')
      return
    }
    const params = JSON.parse(JSON.stringify(this.$route.query))
    this.get_receive_url(params)
  },
  methods: {
    get_receive_url (params) {
      return axios({
        url: common.cityCloudUrl + '/api/enterprise.coupon.get_receive_url',
        method: 'POST',
        data: params,
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then(res => {
        const d = res && res.data
        if (d.code === 10000) {
          window.location.replace(d.url)
        } else if (d.code === common.RespMsg.CustomError.code) {
          this.skipError(d.msg)
        } else {
          if (d.msg) {
            this.skipError('加载异常，返回出错：' + d.msg)
          } else {
            this.skipError('加载异常，返回出错')
          }
        }
      }).catch(e => {
        console.log('get_receive_url4', e)
        this.skipError('加载异常，网络出错',e.msg)
      })
    },
    skipError (msg) {
      this.$dialog.alert({
        title: '提示',
        message: msg || '跳转参数出错'
      }).then(() => {
        this.showText = '关闭页面中...'
        if (common.getUserAgent() == 'wx') {
          this.WX_Bridge_ready(() => {
            WeixinJSBridge.call('closeWindow')
          })
        } else if (common.getUserAgent == 'zfb') {
          this.ZFBReady(() => {
            // ap.popWindow()
            AlipayJSBridge.call('popWindow', {
              data: {
                from: location.href,
                info: Date.now()
              }
            })
          })
        }
      })
    },
    WX_Bridge_ready (cb) {
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', cb(), false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', cb())
          document.attachEvent('onWeixinJSBridgeReady', cb())
        }
      } else {
        cb()
      }
    },
    ZFBReady (callback) {
      if (window.AlipayJSBridge) {
        callback && callback()
      } else {
        if (document.addEventListener) {
          document.addEventListener('AlipayJSBridgeReady', callback, false)
        } else if (document.attachEvent) {
          document.attachEvent('AlipayJSBridgeReady', callback)
        }
      }
    }
  }
}
</script>

<style scoped>
.root_layout {
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
